<template>
  <div class="wrap-container">
    <div class="static-container-header ">
      <h5 class="static-container-header__title u_t-transfrom-cap">
        <div class="static-container-header__left u_c--pointer" @click="$router.back">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="u_c--pointer"
          >
            <path
              d="M23.9730573,7.39798482 L13.4139164,17.3532383 C13.0286944,17.7164292 12.4271498,17.7164292 12.0419277,17.3532383 L1.48278682,7.39798482 C1.36223296,7.28432556 1.35664379,7.09445831 1.47030306,6.97390446 C1.56111944,6.87757914 1.70433913,6.85216956 1.8227492,6.9113746 L12.2807085,12.1403542 C12.562236,12.281118 12.8936082,12.281118 13.1751357,12.1403542 L23.6330949,6.9113746 C23.7812885,6.83727783 23.9614904,6.89734513 24.0355872,7.04553868 C24.0947922,7.16394875 24.0693826,7.30716844 23.9730573,7.39798482 Z"
              id="矩形"
              fill="#333333"
              mask="url(#mask-2)"
              transform="translate(12.727922, 12.000000) rotate(-270.000000) translate(-12.727922, -12.000000) "
            ></path>
          </svg>
          <span>{{ $t('ui_text_010') }} > {{ item.companyName }}</span>
        </div>
        <div class="static-container-header__right"></div>
      </h5>
    </div>
    <div class="flex flex-center flex-wrap flex title" style="position: relative">
      <el-row
        gutter="10"
        type="flex"
        justify="start"
        align="middle"
        class="u_p--t10 u_w--100p control-row"
      >
        <span class="row-title u_m-r9">{{ $t('rp_acc') }}</span>
        <span class="text-primary" v-text="item.userName"></span>
      </el-row>
      <el-row
        gutter="10"
        type="flex"
        justify="center"
        align="bottom"
        class="u_p--t12 background-day text-night u_w--100p"
        key="sum1"
        v-if="['a', 'b', 'f', 'j'].includes(item.categoryCode)"
      >
        <el-col :span="24" class="u_m--l10">
          <span class="u_w--100p row-title">{{ $t('ui_text_009') }}</span>
        </el-col>
        <el-col :span="24" class="u_m--l10">
          <el-row
            gutter="10"
            type="flex"
            justify="center"
            class="background-day text-night u_w--100p control-panel u_m--t10 u_m--b10"
          >
            <el-select style="flex: 3" class="u_m-r9 platform" v-model="search.status">
              <el-option
                v-for="item in columns"
                :key="item.value"
                :label="item.text"
                :value="+item.value"
              />
            </el-select>
            <el-button type="primary" size="medium" class="main-btn" @click="onSearch">{{
              $t('ui_search_for')
            }}</el-button>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-tabs
      animated
      class="day-control u_m--t20"
      type="card"
      :stretch="true"
      :lazy="true"
      @tab-click="onSearch(null)"
      v-model="defaultActive"
    >
      <el-tab-pane v-for="day in dayList" :label="day.text" :name="day.id" :key="day.id">
      </el-tab-pane>
    </el-tabs>
    <custom-table
      :titleList="settings.table.title"
      :list="showList"
      :title-class="'background-th'"
      :table="settings.table"
      @click="handleTrClick"
      :pagination="pagination"
      @change="handleChange"
      :loading="tableLoading"
      style="word-break: 'break-all'"
    >
      <template v-slot:default="{item: {value, key, item}}">
        <span v-if="key === 'statusText'" :class="['title', `${getValueClass(item, key)}`]">
          <i :class="`el-icon-${getIcon(item, key)}`"></i>
          {{ value }}</span
        >
        <span v-if="key === 'bonus'" :class="['title', `${getValueClass(item, key)}`]">
          {{ value }}</span
        >
        <span
          v-if="['profit', 'winLose'].includes(key)"
          :class="['title', value > 0 ? 'text-tomato' : value < 0 ? 'text-grass' : '']"
        >
          {{ value > 0 ? '+' : '' }}{{ value }}</span
        >
      </template>

      <template #sum>
        <el-row
          class="text-night u_fw--bold background-button table-sum flex-center"
          type="flex"
          justify="center"
          align="center"
        >
          <span style="flex: 1" :class="`u_t--center`">
            {{ $t('rp_total_amount') }}
          </span>
          <template v-for="n in settings.table.keys.length - 1">
            <span
              style="flex: 1"
              :key="n"
              v-if="settings.table.keys[n] === 'winLose'"
              :class="
                `u_t--center
              text-${
                parseInt(calculate.pageWinLose) > 0
                  ? 'tomato'
                  : parseInt(calculate.pageWinLose) < 0
                  ? 'grass'
                  : 'night'
              }`
              "
            >
              {{ calculate.pageWinLose }}
            </span>
            <span v-else style="flex: 1" :key="`empty${n}`"> </span>
          </template>
        </el-row>
      </template>
    </custom-table>

    <tips :rules="rules" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {
  ElMessageBox,
  ElMessage,
  ElInput,
  ElDialog,
  ElButton,
  ElRow,
  ElCol,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElLoading,
  ElTabs,
  ElTabPane,
  ElIcon
} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import Tips from '@/common/web/tips/index.vue';
import eventBus from '@/web/eventBus';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';

import {gameRecord} from '@/services/server/web/game-record';
import {IGameDetailResult, IGameDetail, IGame} from '@/model/game-record';

const moment = {
  getDate: function({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),
    date = new Date().getDate(),
    hour = new Date().getHours(),
    minute = new Date().getMinutes(),
    second = new Date().getSeconds(),
    millisecond = new Date().getMilliseconds()
  }) {
    let now = new Date();
    const setMap = new Map([
      ['year', now.setFullYear(year)],
      ['month', now.setMonth(month)],
      ['date', now.setDate(date)],
      ['hour', now.setHours(hour)],
      ['minute', now.setMinutes(minute)],
      ['second', now.setSeconds(second)],
      ['millisecond', now.setMilliseconds(millisecond)]
    ]);
    setMap.forEach((value, key) => {
      const time = setMap.get(key) || +new Date();
      now = new Date(time);
    });
    return now;
  }
};

@Options({
  components: {
    ElMessageBox,
    ElMessage,
    ElInput,
    ElDialog,
    ElButton,
    ElRow,
    ElCol,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElLoading,
    ElTabs,
    ElTabPane,
    CustomTable,
    ElIcon,
    Tips
  }
})
export default class MemberListDetail extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥',
    game_company: '[]'
  };
  userInfo = App.getUserinfo();
  search = {
    userName: '',
    newTask: true,
    status: 1,
    lotteryStatus: 6,
    company: 0,
    categoryCode: '',
    startDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD')
  };
  defaultActive = 0;
  loading = false;
  active = 0;
  rules = ['ui_text_0084'];
  showList: IGameDetail[] = [];
  list: IGameDetail[] = [];
  item: IGame = {
    bet: '',
    betCount: 0,
    categoryCode: '',
    companyId: 0,
    companyName: '',
    userId: 0,
    userName: '',
    winLose: ''
  };
  columns: {
    text: string;
    value: string;
  }[] = [];
  isLoading = false;
  details = {
    table: {},
    title: {}
  };
  settings = {
    columns: [],
    detail: {
      keys: [],
      title: []
    },
    table: {
      title: [],
      keys: []
    }
  };
  pagination = {
    page: 1,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  timer: null | number = null;
  pageLoading = false;
  calculate: {
    pageWinLose: number;
    totalWinLose: number;
  } = {
    pageWinLose: 0,
    totalWinLose: 0
  };
  get dayList() {
    return [
      {
        text: this.$t('ui_nowadays'),
        id: 0
      },
      {
        text: this.$t('ui_yesterday'),
        id: 1
      },
      {
        text: this.$t('ui_last_seven_days'),
        id: 6
      },
      {
        text: this.$t('ui_last_month'),
        id: 29
      }
    ];
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    this.init();
    eventBus.bus$on('langChange', () => {
      // this.init();
      if (this.$route.name === 'MemberGameRecordDetail') {
        this.$router.replace({
          name: 'MemberGameRecord'
        });
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  async init() {
    const item = JSON.parse(sessionStorage.getItem('userListDetail') || '') || {};
    this.item = item;
    const config = ['a', 'b', 'f', 'j'].includes(this.item.categoryCode)
      ? this.item.categoryCode
      : 'other';
    await import(`@/web/operation/memberList/gameRecord/config/${config}`).then(res => {
      const list: {value: string; text: string}[] = [...res.default.columns];
      // list.unshift({text: this.$t('ui_all'), value: ''});
      this.columns = list.map(v => {
        v.text = this.$t(v.text);
        return v;
      });
      this.settings = res.default;
      this.search.status = +this.columns[0].value;
    });
    const active = this.item.active || 0;
    this.defaultActive = +active;
    this.onSearch();
  }
  toRoute(name: string) {
    this.$router.push({name});
  }
  handleChange(val: {page: number}) {
    this.onSearch(val.page);
  }
  async onSearch(val?: {type: string} | number | undefined) {
    console.log('onSearch val: ', val);
    this.pageLoading = true;
    if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
      this.search.newTask = false;
    }
    //dosearch
    // this.changeVal();

    this.changeVal(this.defaultActive);
    let params: {
      categoryCode: string;
      page: number;
      page_limit: number;
      user_id: number;
      company: number;
      start_date: string;
      end_date: string;
      lotteryStatus?: number | string;
      status?: number | string;
    } = {
      categoryCode: this.item.categoryCode,
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_id: this.item.userId,
      company: this.item.companyId,
      start_date: this.search.startDate,
      end_date: this.search.endDate
    };
    if (this.item.categoryCode === 'a') {
      params = {
        ...params,
        lotteryStatus: +this.search.status
      };
    } else {
      params = {
        ...params,
        status: +this.search.status
      };
    }

    if (!val || (typeof val === 'object' && val['type'])) {
      this.search.newTask = true;
      this.showList = [];
    } else {
      if (val) {
        params = {
          ...params,
          page: +val
        };
      }
    }
    console.log(this.pagination, this.search, this.showList, 'this.pagination');
    const data = await gameRecord.detail<IGameDetailResult>(params);
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
      this.calculate = data.calculate;
      /* disbale-eslint-next-line */
      this.item.companyName = data.company_name;
    }
    setTimeout(() => {
      this.pageLoading = false;
    });
  }
  changeVal(val: number) {
    console.log(' changeVal val: ', val);
    this.active = val;
    const [startDate, endDate] = this.setDate(val);
    this.search.startDate = startDate;
    this.search.endDate = endDate;
  }
  setDate(val: number, formatPattern = 'YYYY-MM-DD') {
    let tempDate = new Date();
    if (typeof val === 'number') {
      tempDate = Format.TimeHandle.subtract(new Date(), val, 'date');
    }
    const dateMap = new Map([
      [
        0,
        [
          moment.getDate({hour: 0, minute: 0, second: 0}),
          moment.getDate({hour: 23, minute: 59, second: 59})
        ]
      ],
      [
        1,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        6,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        29,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        60,
        [
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 2,
            date: new Date().getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        365,
        [
          moment.getDate({
            year: new Date().getFullYear() - 1,
            month: new Date().getMonth(),
            date: 1,
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ]
    ]);
    const dateArr = dateMap.get(val) || [];
    return [
      Format.TimeHandle.format(dateArr[0], formatPattern),
      Format.TimeHandle.format(dateArr[1], formatPattern)
    ];
  }

  getValueClass(item: any, key: string) {
    let _class = 'text-night';
    switch (key) {
      case 'statusText':
        _class = [2, 3, 5].includes(item.status)
          ? 'text-primary'
          : [4].includes(item.status)
          ? 'text-tomato'
          : 'text-grass';
        break;
      case 'bonus':
        _class =
          parseInt(item.bonus) < 0
            ? 'text-grass'
            : parseInt(item.bonus) > 0
            ? 'text-tomato'
            : 'text-night';
        break;
    }
    return _class;
  }
  getIcon(item: any, key: string) {
    let _class = '';
    switch (key) {
      case 'statusText':
        _class = [2, 3, 5].includes(item.status)
          ? 'check'
          : [4].includes(item.status)
          ? 'close'
          : 'info';
        break;
    }
    return _class;
  }
}
</script>
<style lang="scss" scoped>
.static-container-header__title {
  color: #000;
  font-size: 28px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
}
.static-container-header__left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}
.static-container-header__right {
  color: $bg;
  font-size: 14px;
}

.day-control {
  width: 100%;
  :deep(.el-tabs__nav-wrap) {
    width: 100%;
  }
  :deep(.el-tabs__item) {
    flex: 1;
    background-color: rgba(31, 85, 240, 0.1);
    font-weight: bold;
    &.is-active {
      border-bottom: 2px solid #1f55f0;
      color: #1f55f0;
    }
  }
}
.u_t--center {
  text-align: center;
}
.u_c--pointer {
  cursor: pointer;
}
.sum-row {
  background-color: #d9dee2;
  font-weight: bold;
}
.main-btn {
  height: 40px;
  color: $cf;
  background-color: $bg;
  border-radius: 8px /* 8/25 */;
  font-size: 16px;
  border-width: 0;
}
.table-sum {
  height: 48px;
  font-size: 14px;
}
.u_fw--bold {
  font-weight: bold;
}
.control-row {
  font-size: 14px;
  padding: 10px;
}
.u_m--l10 {
  margin-left: 10px;
}
.u_m--t10 {
  margin-top: 10px;
}
.u_m--b10 {
  margin-bottom: 10px;
}
</style>
