
import {Options, Vue} from 'vue-class-component';
import {
  ElMessageBox,
  ElMessage,
  ElInput,
  ElDialog,
  ElButton,
  ElRow,
  ElCol,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElLoading,
  ElTabs,
  ElTabPane,
  ElIcon
} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import Tips from '@/common/web/tips/index.vue';
import eventBus from '@/web/eventBus';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';

import {gameRecord} from '@/services/server/web/game-record';
import {IGameDetailResult, IGameDetail, IGame} from '@/model/game-record';

const moment = {
  getDate: function({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),
    date = new Date().getDate(),
    hour = new Date().getHours(),
    minute = new Date().getMinutes(),
    second = new Date().getSeconds(),
    millisecond = new Date().getMilliseconds()
  }) {
    let now = new Date();
    const setMap = new Map([
      ['year', now.setFullYear(year)],
      ['month', now.setMonth(month)],
      ['date', now.setDate(date)],
      ['hour', now.setHours(hour)],
      ['minute', now.setMinutes(minute)],
      ['second', now.setSeconds(second)],
      ['millisecond', now.setMilliseconds(millisecond)]
    ]);
    setMap.forEach((value, key) => {
      const time = setMap.get(key) || +new Date();
      now = new Date(time);
    });
    return now;
  }
};

@Options({
  components: {
    ElMessageBox,
    ElMessage,
    ElInput,
    ElDialog,
    ElButton,
    ElRow,
    ElCol,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElLoading,
    ElTabs,
    ElTabPane,
    CustomTable,
    ElIcon,
    Tips
  }
})
export default class MemberListDetail extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥',
    game_company: '[]'
  };
  userInfo = App.getUserinfo();
  search = {
    userName: '',
    newTask: true,
    status: 1,
    lotteryStatus: 6,
    company: 0,
    categoryCode: '',
    startDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD')
  };
  defaultActive = 0;
  loading = false;
  active = 0;
  rules = ['ui_text_0084'];
  showList: IGameDetail[] = [];
  list: IGameDetail[] = [];
  item: IGame = {
    bet: '',
    betCount: 0,
    categoryCode: '',
    companyId: 0,
    companyName: '',
    userId: 0,
    userName: '',
    winLose: ''
  };
  columns: {
    text: string;
    value: string;
  }[] = [];
  isLoading = false;
  details = {
    table: {},
    title: {}
  };
  settings = {
    columns: [],
    detail: {
      keys: [],
      title: []
    },
    table: {
      title: [],
      keys: []
    }
  };
  pagination = {
    page: 1,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  timer: null | number = null;
  pageLoading = false;
  calculate: {
    pageWinLose: number;
    totalWinLose: number;
  } = {
    pageWinLose: 0,
    totalWinLose: 0
  };
  get dayList() {
    return [
      {
        text: this.$t('ui_nowadays'),
        id: 0
      },
      {
        text: this.$t('ui_yesterday'),
        id: 1
      },
      {
        text: this.$t('ui_last_seven_days'),
        id: 6
      },
      {
        text: this.$t('ui_last_month'),
        id: 29
      }
    ];
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    this.init();
    eventBus.bus$on('langChange', () => {
      // this.init();
      if (this.$route.name === 'MemberGameRecordDetail') {
        this.$router.replace({
          name: 'MemberGameRecord'
        });
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  async init() {
    const item = JSON.parse(sessionStorage.getItem('userListDetail') || '') || {};
    this.item = item;
    const config = ['a', 'b', 'f', 'j'].includes(this.item.categoryCode)
      ? this.item.categoryCode
      : 'other';
    await import(`@/web/operation/memberList/gameRecord/config/${config}`).then(res => {
      const list: {value: string; text: string}[] = [...res.default.columns];
      // list.unshift({text: this.$t('ui_all'), value: ''});
      this.columns = list.map(v => {
        v.text = this.$t(v.text);
        return v;
      });
      this.settings = res.default;
      this.search.status = +this.columns[0].value;
    });
    const active = this.item.active || 0;
    this.defaultActive = +active;
    this.onSearch();
  }
  toRoute(name: string) {
    this.$router.push({name});
  }
  handleChange(val: {page: number}) {
    this.onSearch(val.page);
  }
  async onSearch(val?: {type: string} | number | undefined) {
    console.log('onSearch val: ', val);
    this.pageLoading = true;
    if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
      this.search.newTask = false;
    }
    //dosearch
    // this.changeVal();

    this.changeVal(this.defaultActive);
    let params: {
      categoryCode: string;
      page: number;
      page_limit: number;
      user_id: number;
      company: number;
      start_date: string;
      end_date: string;
      lotteryStatus?: number | string;
      status?: number | string;
    } = {
      categoryCode: this.item.categoryCode,
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_id: this.item.userId,
      company: this.item.companyId,
      start_date: this.search.startDate,
      end_date: this.search.endDate
    };
    if (this.item.categoryCode === 'a') {
      params = {
        ...params,
        lotteryStatus: +this.search.status
      };
    } else {
      params = {
        ...params,
        status: +this.search.status
      };
    }

    if (!val || (typeof val === 'object' && val['type'])) {
      this.search.newTask = true;
      this.showList = [];
    } else {
      if (val) {
        params = {
          ...params,
          page: +val
        };
      }
    }
    console.log(this.pagination, this.search, this.showList, 'this.pagination');
    const data = await gameRecord.detail<IGameDetailResult>(params);
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
      this.calculate = data.calculate;
      /* disbale-eslint-next-line */
      this.item.companyName = data.company_name;
    }
    setTimeout(() => {
      this.pageLoading = false;
    });
  }
  changeVal(val: number) {
    console.log(' changeVal val: ', val);
    this.active = val;
    const [startDate, endDate] = this.setDate(val);
    this.search.startDate = startDate;
    this.search.endDate = endDate;
  }
  setDate(val: number, formatPattern = 'YYYY-MM-DD') {
    let tempDate = new Date();
    if (typeof val === 'number') {
      tempDate = Format.TimeHandle.subtract(new Date(), val, 'date');
    }
    const dateMap = new Map([
      [
        0,
        [
          moment.getDate({hour: 0, minute: 0, second: 0}),
          moment.getDate({hour: 23, minute: 59, second: 59})
        ]
      ],
      [
        1,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        6,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        29,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        60,
        [
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 2,
            date: new Date().getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        365,
        [
          moment.getDate({
            year: new Date().getFullYear() - 1,
            month: new Date().getMonth(),
            date: 1,
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ]
    ]);
    const dateArr = dateMap.get(val) || [];
    return [
      Format.TimeHandle.format(dateArr[0], formatPattern),
      Format.TimeHandle.format(dateArr[1], formatPattern)
    ];
  }

  getValueClass(item: any, key: string) {
    let _class = 'text-night';
    switch (key) {
      case 'statusText':
        _class = [2, 3, 5].includes(item.status)
          ? 'text-primary'
          : [4].includes(item.status)
          ? 'text-tomato'
          : 'text-grass';
        break;
      case 'bonus':
        _class =
          parseInt(item.bonus) < 0
            ? 'text-grass'
            : parseInt(item.bonus) > 0
            ? 'text-tomato'
            : 'text-night';
        break;
    }
    return _class;
  }
  getIcon(item: any, key: string) {
    let _class = '';
    switch (key) {
      case 'statusText':
        _class = [2, 3, 5].includes(item.status)
          ? 'check'
          : [4].includes(item.status)
          ? 'close'
          : 'info';
        break;
    }
    return _class;
  }
}
