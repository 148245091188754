<template>
  <div class="wrap-container">
    <div class="static-container-header ">
      <h5 class="static-container-header__title u_t-transfrom-cap">
        <div class="static-container-header__left u_c--pointer" @click="$router.back">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M23.9730573,7.39798482 L13.4139164,17.3532383 C13.0286944,17.7164292 12.4271498,17.7164292 12.0419277,17.3532383 L1.48278682,7.39798482 C1.36223296,7.28432556 1.35664379,7.09445831 1.47030306,6.97390446 C1.56111944,6.87757914 1.70433913,6.85216956 1.8227492,6.9113746 L12.2807085,12.1403542 C12.562236,12.281118 12.8936082,12.281118 13.1751357,12.1403542 L23.6330949,6.9113746 C23.7812885,6.83727783 23.9614904,6.89734513 24.0355872,7.04553868 C24.0947922,7.16394875 24.0693826,7.30716844 23.9730573,7.39798482 Z"
              id="矩形"
              fill="#333333"
              mask="url(#mask-2)"
              transform="translate(12.727922, 12.000000) rotate(-270.000000) translate(-12.727922, -12.000000) "
            ></path>
          </svg>
          <span>{{ $t('ui_text_010') }}</span>
        </div>
        <div class="static-container-header__right"></div>
      </h5>
    </div>
    <el-row type="flex" align="bottom" justify="start" gutter="10" class="u_m--l10 u_m--r20">
      <el-col :span="5" class="flex flex-column flex-center flex-wrap">
        <label class="el-form-item__label">{{ $t('rp_acc') }}</label>
        <div class="u_w--100p">
          <el-input
            v-model="search.userName"
            :autofocus="true"
            background="none"
            left-icon=""
            class="opacity-search"
            :placeholder="$t('ui_please_enter_user_name_new')"
            disabled
          />
        </div>
      </el-col>
      <el-col :span="5" class="flex flex-column flex-center flex-wrap">
        <label class="el-form-item__label">{{ $t('rp_gaming_platform') }}</label>
        <div class="u_w--100p">
          <el-select style="flex: 1" class="u_m-r9 platform" v-model="search.companyId">
            <el-option
              v-for="item in columns"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            />
          </el-select>
        </div>
      </el-col>
      <el-col :span="3" class="flex flex-column flex-center flex-justify-end flex-wrap">
        <div style="height: 40px"></div>
        <el-button
          type="primary"
          class="c_button--huge j_btn-bg--primary j_btn-txt--primary main-btn"
          @click="onSearch"
          >{{ $t('ui_search_for') }}</el-button
        >
      </el-col>
    </el-row>

    <el-tabs
      animated
      class="day-control u_m--t20"
      type="card"
      :stretch="true"
      :lazy="true"
      @tab-click="onSearch(null)"
      v-model="defaultActive"
    >
      <el-tab-pane v-for="day in dayList" :label="day.text" :name="day.id" :key="day.id">
      </el-tab-pane>
    </el-tabs>
    <custom-table
      :titleList="titleList"
      :list="showList"
      :title-class="'background-th'"
      :table="table"
      @click="handleTrClick"
      :pagination="pagination"
      @change="handleChange"
      :loading="tableLoading"
    >
      <template v-slot:default="{item: {value, key}}">
        <span v-if="key === 'betCount'" class="u_c--pointer u_underline">{{ value }}</span>
        <span
          v-if="key === 'winLose'"
          :class="[value > 0 ? 'text-tomato' : value < 0 ? 'text-grass' : '']"
          >{{ value }}</span
        >
      </template>
      <template #sum>
        <el-row
          class="text-night u_fw--bold background-button table-sum flex-center"
          type="flex"
          justify="center"
          align="center"
        >
          <span style="flex: 1" :class="`u_t--center`">
            {{ $t('ui_web_text_008') }}
          </span>
          <template v-for="n in table.keys.length - 1">
            <span
              style="flex: 1"
              :key="n"
              v-if="table.keys[n] === 'winLose'"
              :class="
                `u_t--center
              text-${
                parseInt(total.total_win) > 0
                  ? 'tomato'
                  : parseInt(total.total_win) < 0
                  ? 'grass'
                  : 'night'
              }`
              "
            >
              {{ +total.total_win > 0 ? '+' : '' }}{{ total.total_win }}
            </span>
            <span
              v-else-if="['bet'].includes(table.keys[n])"
              style="flex: 1"
              :key="`${table.keys[n]}${n}`"
              :class="`u_t--center`"
            >
              {{ total.total_bet }}
            </span>
            <span
              v-else-if="['betCount'].includes(table.keys[n])"
              style="flex: 1"
              :key="`${table.keys[n]}${n}`"
              :class="`u_t--center`"
            >
              {{ total.total_count }}
            </span>
            <span v-else style="flex: 1" :key="`empty${n}`"> </span>
          </template>
        </el-row>
      </template>
    </custom-table>

    <tips :rules="rules" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {
  ElMessageBox,
  ElMessage,
  ElInput,
  ElDialog,
  ElButton,
  ElRow,
  ElCol,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElLoading,
  ElTabs,
  ElTabPane
} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import Tips from '@/common/web/tips/index.vue';
import eventBus from '@/web/eventBus';

import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';

import {gameRecord} from '@/services/server/web/game-record';
import Common from '@/services/server/web/common';
import {IGameResult, IGame} from '@/model/game-record';

const moment = {
  getDate: function({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),
    date = new Date().getDate(),
    hour = new Date().getHours(),
    minute = new Date().getMinutes(),
    second = new Date().getSeconds(),
    millisecond = new Date().getMilliseconds()
  }) {
    let now = new Date();
    const setMap = new Map([
      ['year', now.setFullYear(year)],
      ['month', now.setMonth(month)],
      ['date', now.setDate(date)],
      ['hour', now.setHours(hour)],
      ['minute', now.setMinutes(minute)],
      ['second', now.setSeconds(second)],
      ['millisecond', now.setMilliseconds(millisecond)]
    ]);
    setMap.forEach((value, key) => {
      const time = setMap.get(key) || +new Date();
      now = new Date(time);
    });
    return now;
  }
};

@Options({
  components: {
    ElMessageBox,
    ElMessage,
    ElInput,
    ElDialog,
    ElButton,
    ElRow,
    ElCol,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElLoading,
    ElTabs,
    ElTabPane,
    CustomTable,
    Tips
  }
})
export default class MemberList extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥',
    game_company: '[]'
  };
  userInfo = App.getUserinfo();
  search = {
    userName: '',
    newTask: true,
    searchType: 1,
    companyId: '',
    startDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    user_id: ''
  };
  defaultActive = 0;
  loading = false;
  today = new Date();

  active = 0;
  showList: IGame[] = [];
  list: IGame[] = [];
  total = {
    total_bet: '',
    total_count: 0,
    total_win: ''
  };
  isLoading = false;

  rules = ['ui_text_0084'];
  tableLoading = false;
  pagination = {
    page: 0,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  titleList = [
    {
      key: 'rp_acc',
      name: 'rp_acc'
    },
    {
      key: 'ui_platform_v2',
      name: 'ui_platform_v2'
    },
    {
      key: 'ui_text_018',
      name: 'ui_text_018'
    },
    {
      key: 'rp_bet',
      name: 'rp_bet'
    },
    {
      key: 'ui_text_016',
      name: 'ui_text_016'
    }
  ];
  table = {
    keys: ['userName', 'companyName', 'betCount', 'bet', 'winLose']
  };
  timer: null | number = null;
  get dayList() {
    return [
      {
        text: this.$t('ui_nowadays'),
        id: 0
      },
      {
        text: this.$t('ui_yesterday'),
        id: 1
      },
      {
        text: this.$t('ui_last_seven_days'),
        id: 6
      },
      {
        text: this.$t('ui_last_month'),
        id: 29
      }
    ];
  }
  get columns() {
    const list: {
      text: string;
      value: string;
    }[] = [
      {
        text: this.$t('msgCenter__all'),
        value: ''
      }
    ];
    const gameCompany = JSON.parse(this.config.game_company);
    gameCompany.map((v: {id: string; name: string}) => {
      list.push({
        value: v.id,
        text: v.name
      });
    });
    return list;
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    this.init();
    eventBus.bus$on('langChange', async () => {
      if (this.$route.name === 'MemberGameRecord') {
        await Common.getConfig({});
        this.config = App.getConfig() || {
          dollarSign: '¥',
          game_company: '[]'
        };
        this.init();
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  init() {
    const showDetail = this.$route.params.user && this.$route.params.companyId;
    if (!showDetail) {
      const item = sessionStorage.getItem('userListDetail')
        ? JSON.parse(sessionStorage.getItem('userListDetail') || '')
        : {};
      if (Object.keys(item).length) {
        this.defaultActive = item.active;
        const {userName, companyId, searchType, startDate, endDate} = item;
        this.search = {
          userName: userName,
          companyId: companyId + '',
          newTask: true,
          user_id: '',
          searchType,
          startDate,
          endDate
        };
        // this.changeVal(item.active);
      } else {
        const id = typeof this.$route.params.id === 'string' && this.$route.params.id;
        if (id) {
          this.search.userName = id.split('-')[1] || '';
          this.search.user_id = id.split('-')[0] || '';
        }
      }
      this.onSearch();
    }
  }
  toRoute(name: string) {
    this.$router.push({name});
  }
  handleChange(item: {limit: number; page: number}) {
    console.log('handleChange item: ', item);
    this.pagination.pageLimit = item.limit;
    this.onSearch(item.page);
  }
  async onSearch(val?: number | undefined) {
    console.log('onSearch val: ', val);
    this.tableLoading = true;
    this.isLoading = true;
    if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
      sessionStorage.removeItem('userListDetail');
    }
    if (!val || (typeof val === 'object' && val['type'])) {
      this.search.newTask = true;
      this.showList = [];
      this.pagination.page = 1;
    } else {
      if (val) {
        this.pagination.page = val;
      }
    }

    console.log(this.pagination, this.search, 'this.pagination');
    //dosearch
    const id = typeof this.$route.params.id === 'string' && this.$route.params.id;
    if (id) {
      this.search.userName = id.split('-')[1] || '';
      this.search.user_id = id.split('-')[0] || '';
    }
    this.changeVal(this.defaultActive);
    let params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_name: this.search.userName,
      user_id: this.search.user_id,
      search_type: 1,
      start_date: this.search.startDate,
      end_date: this.search.endDate,
      company_id: ''
    };
    if (this.search.companyId) {
      params = {
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_id: this.search.companyId
      };
    }
    // await this.getList(this.$snakeCase(params));
    const data = await gameRecord.read<IGameResult>(params);
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
      this.total = data.total;
    }
    setTimeout(() => {
      this.isLoading = false;
      this.tableLoading = false;
    });
  }
  handleTrClick(v: IGame) {
    this.$router.push({
      name: 'MemberGameRecordDetail',
      params: {
        user: v.userId,
        companyId: v.companyId,
        categoryCode: v.categoryCode
      }
    });
    sessionStorage.setItem(
      'userListDetail',
      JSON.stringify({
        ...v,
        active: this.active
      })
    );
  }
  changeVal(val: number) {
    this.active = val;
    const [startDate, endDate] = this.setDate(val);
    this.search.startDate = startDate;
    this.search.endDate = endDate;
    // this.timer = setTimeout(() => {
    //   clearTimeout(this.timer || 0);
    //   this.onSearch();
    // });
  }
  setDate(val: number, formatPattern = 'YYYY-MM-DD') {
    let tempDate = new Date();
    if (typeof val === 'number') {
      tempDate = Format.TimeHandle.subtract(new Date(), val, 'date');
    }
    const dateMap = new Map([
      [
        0,
        [
          moment.getDate({hour: 0, minute: 0, second: 0}),
          moment.getDate({hour: 23, minute: 59, second: 59})
        ]
      ],
      [
        1,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        6,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        29,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        60,
        [
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 2,
            date: new Date().getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        365,
        [
          moment.getDate({
            year: new Date().getFullYear() - 1,
            month: new Date().getMonth(),
            date: 1,
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ]
    ]);
    const dateArr = dateMap.get(+val) || [];
    return [
      Format.TimeHandle.format(dateArr[0], formatPattern),
      Format.TimeHandle.format(dateArr[1], formatPattern)
    ];
  }
}
</script>
<style lang="scss" scoped>
.u_t--center {
  text-align: center;
}

.static-container-header__title {
  color: #000;
  font-size: 28px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
}
.static-container-header__left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}
.static-container-header__right {
  color: $bg;
  font-size: 14px;
}
.day-control {
  width: 100%;
  :deep(.el-tabs__nav-wrap) {
    width: 100%;
  }
  :deep(.el-tabs__item) {
    flex: 1;
    background-color: rgba(31, 85, 240, 0.1);
    font-weight: bold;
    &.is-active {
      border-bottom: 2px solid #1f55f0;
      color: #1f55f0;
    }
  }
}

.opacity-search {
  background-color: #f6f7f8;
  border-radius: 8px /* 8/25 */;
  height: 40px /* 42/25 */;
}
.main-btn {
  height: 40px;
  color: $cf;
  background-color: $bg;
  border-radius: 8px /* 8/25 */;
  font-size: 16px;
  border-width: 0;
}
.u_m--t20 {
  margin-top: 20px;
}
.u_c--pointer {
  cursor: pointer;
}
.u_underline {
  text-decoration: underline;
  color: $bg;
}

.table-sum {
  height: 48px;
  font-size: 14px;
}
.u_fw--bold {
  font-weight: bold;
}
</style>
