import doFetch from '../../fetch';
const AGENTPATH = '/api/agent';

const actions = {
  read: async <T>(data: any) => await doFetch<T>(`${AGENTPATH}/v1/Users/game_record`, 'POST', data),
  detail: async <T>(data: any) =>
    await doFetch<T>(`${AGENTPATH}/v1/Users/game_record_detail`, 'POST', data)
};

export const gameRecord = actions;
