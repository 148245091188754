
import {Options, Vue} from 'vue-class-component';
import {
  ElMessageBox,
  ElMessage,
  ElInput,
  ElDialog,
  ElButton,
  ElRow,
  ElCol,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElLoading,
  ElTabs,
  ElTabPane
} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import Tips from '@/common/web/tips/index.vue';
import eventBus from '@/web/eventBus';

import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';

import {gameRecord} from '@/services/server/web/game-record';
import Common from '@/services/server/web/common';
import {IGameResult, IGame} from '@/model/game-record';

const moment = {
  getDate: function({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),
    date = new Date().getDate(),
    hour = new Date().getHours(),
    minute = new Date().getMinutes(),
    second = new Date().getSeconds(),
    millisecond = new Date().getMilliseconds()
  }) {
    let now = new Date();
    const setMap = new Map([
      ['year', now.setFullYear(year)],
      ['month', now.setMonth(month)],
      ['date', now.setDate(date)],
      ['hour', now.setHours(hour)],
      ['minute', now.setMinutes(minute)],
      ['second', now.setSeconds(second)],
      ['millisecond', now.setMilliseconds(millisecond)]
    ]);
    setMap.forEach((value, key) => {
      const time = setMap.get(key) || +new Date();
      now = new Date(time);
    });
    return now;
  }
};

@Options({
  components: {
    ElMessageBox,
    ElMessage,
    ElInput,
    ElDialog,
    ElButton,
    ElRow,
    ElCol,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElLoading,
    ElTabs,
    ElTabPane,
    CustomTable,
    Tips
  }
})
export default class MemberList extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥',
    game_company: '[]'
  };
  userInfo = App.getUserinfo();
  search = {
    userName: '',
    newTask: true,
    searchType: 1,
    companyId: '',
    startDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    user_id: ''
  };
  defaultActive = 0;
  loading = false;
  today = new Date();

  active = 0;
  showList: IGame[] = [];
  list: IGame[] = [];
  total = {
    total_bet: '',
    total_count: 0,
    total_win: ''
  };
  isLoading = false;

  rules = ['ui_text_0084'];
  tableLoading = false;
  pagination = {
    page: 0,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  titleList = [
    {
      key: 'rp_acc',
      name: 'rp_acc'
    },
    {
      key: 'ui_platform_v2',
      name: 'ui_platform_v2'
    },
    {
      key: 'ui_text_018',
      name: 'ui_text_018'
    },
    {
      key: 'rp_bet',
      name: 'rp_bet'
    },
    {
      key: 'ui_text_016',
      name: 'ui_text_016'
    }
  ];
  table = {
    keys: ['userName', 'companyName', 'betCount', 'bet', 'winLose']
  };
  timer: null | number = null;
  get dayList() {
    return [
      {
        text: this.$t('ui_nowadays'),
        id: 0
      },
      {
        text: this.$t('ui_yesterday'),
        id: 1
      },
      {
        text: this.$t('ui_last_seven_days'),
        id: 6
      },
      {
        text: this.$t('ui_last_month'),
        id: 29
      }
    ];
  }
  get columns() {
    const list: {
      text: string;
      value: string;
    }[] = [
      {
        text: this.$t('msgCenter__all'),
        value: ''
      }
    ];
    const gameCompany = JSON.parse(this.config.game_company);
    gameCompany.map((v: {id: string; name: string}) => {
      list.push({
        value: v.id,
        text: v.name
      });
    });
    return list;
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    this.init();
    eventBus.bus$on('langChange', async () => {
      if (this.$route.name === 'MemberGameRecord') {
        await Common.getConfig({});
        this.config = App.getConfig() || {
          dollarSign: '¥',
          game_company: '[]'
        };
        this.init();
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  init() {
    const showDetail = this.$route.params.user && this.$route.params.companyId;
    if (!showDetail) {
      const item = sessionStorage.getItem('userListDetail')
        ? JSON.parse(sessionStorage.getItem('userListDetail') || '')
        : {};
      if (Object.keys(item).length) {
        this.defaultActive = item.active;
        const {userName, companyId, searchType, startDate, endDate} = item;
        this.search = {
          userName: userName,
          companyId: companyId + '',
          newTask: true,
          user_id: '',
          searchType,
          startDate,
          endDate
        };
        // this.changeVal(item.active);
      } else {
        const id = typeof this.$route.params.id === 'string' && this.$route.params.id;
        if (id) {
          this.search.userName = id.split('-')[1] || '';
          this.search.user_id = id.split('-')[0] || '';
        }
      }
      this.onSearch();
    }
  }
  toRoute(name: string) {
    this.$router.push({name});
  }
  handleChange(item: {limit: number; page: number}) {
    console.log('handleChange item: ', item);
    this.pagination.pageLimit = item.limit;
    this.onSearch(item.page);
  }
  async onSearch(val?: number | undefined) {
    console.log('onSearch val: ', val);
    this.tableLoading = true;
    this.isLoading = true;
    if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
      sessionStorage.removeItem('userListDetail');
    }
    if (!val || (typeof val === 'object' && val['type'])) {
      this.search.newTask = true;
      this.showList = [];
      this.pagination.page = 1;
    } else {
      if (val) {
        this.pagination.page = val;
      }
    }

    console.log(this.pagination, this.search, 'this.pagination');
    //dosearch
    const id = typeof this.$route.params.id === 'string' && this.$route.params.id;
    if (id) {
      this.search.userName = id.split('-')[1] || '';
      this.search.user_id = id.split('-')[0] || '';
    }
    this.changeVal(this.defaultActive);
    let params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_name: this.search.userName,
      user_id: this.search.user_id,
      search_type: 1,
      start_date: this.search.startDate,
      end_date: this.search.endDate,
      company_id: ''
    };
    if (this.search.companyId) {
      params = {
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_id: this.search.companyId
      };
    }
    // await this.getList(this.$snakeCase(params));
    const data = await gameRecord.read<IGameResult>(params);
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
      this.total = data.total;
    }
    setTimeout(() => {
      this.isLoading = false;
      this.tableLoading = false;
    });
  }
  handleTrClick(v: IGame) {
    this.$router.push({
      name: 'MemberGameRecordDetail',
      params: {
        user: v.userId,
        companyId: v.companyId,
        categoryCode: v.categoryCode
      }
    });
    sessionStorage.setItem(
      'userListDetail',
      JSON.stringify({
        ...v,
        active: this.active
      })
    );
  }
  changeVal(val: number) {
    this.active = val;
    const [startDate, endDate] = this.setDate(val);
    this.search.startDate = startDate;
    this.search.endDate = endDate;
    // this.timer = setTimeout(() => {
    //   clearTimeout(this.timer || 0);
    //   this.onSearch();
    // });
  }
  setDate(val: number, formatPattern = 'YYYY-MM-DD') {
    let tempDate = new Date();
    if (typeof val === 'number') {
      tempDate = Format.TimeHandle.subtract(new Date(), val, 'date');
    }
    const dateMap = new Map([
      [
        0,
        [
          moment.getDate({hour: 0, minute: 0, second: 0}),
          moment.getDate({hour: 23, minute: 59, second: 59})
        ]
      ],
      [
        1,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        6,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        29,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        60,
        [
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 2,
            date: new Date().getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        365,
        [
          moment.getDate({
            year: new Date().getFullYear() - 1,
            month: new Date().getMonth(),
            date: 1,
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ]
    ]);
    const dateArr = dateMap.get(+val) || [];
    return [
      Format.TimeHandle.format(dateArr[0], formatPattern),
      Format.TimeHandle.format(dateArr[1], formatPattern)
    ];
  }
}
